.loginContainer {
  width: 100%;
  height: 100vh;
  position: relative;
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bigCircle {
  position: absolute;
  width: 14.75%;
}

.left {
  top: 0;
  left: 0;
}

.right {
  right: 0;
  bottom: 0;
}

.mainLogIn {
  position: relative;
  z-index: 1;
  width: 81%;
  height: 71.7%;
  margin: auto;
  background: #cef3ff;
  opacity: 0.75;
  box-shadow: 0px 4px 22px #b2dfff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dotLeft {
  position: absolute;
  left: 2.86%;
  bottom: 7.5%;
  width: 12.72%;
}

.dotRight {
  position: absolute;
  width: 12.72%;
  top: 5%;
  right: 4.3%;
}

.signForm {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 18rem;
  width: 20rem;
  justify-content: space-between;
  text-align: center;
}

.logo {
  display: flex;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #7d8cc4;
  justify-content: center;
}

.detail {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  justify-content: center;
  color: #000000;
  opacity: 0.6;
}

.inputauth {
  height: 35px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 10px;
}

.btn {
  background: #7d8cc4;
  border-radius: 5px;
  height: 40px;
  cursor: pointer;
  border: none;
  outline: none;
}

.linkLogin {
  width: 100%;
  height: 18px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #7d8cc4;
  justify-content: center;
  text-decoration: none;
}

.signineye {
  position: absolute;
  right: 0.8rem;
  bottom: 7.1885rem;
  cursor: pointer;
}

.signupeye {
  position: absolute;
  right: 0.8rem;
  bottom: 7.85rem;
  cursor: pointer;
}
